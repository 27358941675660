var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model.number",
        value: _vm.value,
        expression: "value",
        modifiers: { number: true },
      },
    ],
    ref: "input",
    staticClass: "input",
    staticStyle: { width: "100%" },
    attrs: { type: "number", disabled: !_vm.editable },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        if ($event.target.composing) return
        _vm.value = _vm._n($event.target.value)
      },
      blur: function ($event) {
        return _vm.$forceUpdate()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }