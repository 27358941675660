<template>
  <input class="input" type="number" v-model.number="value" ref="input" style="width: 100%" :disabled="!editable"/>
</template>

<script>
export default {
  data() {
    return {
      value: null,
      editable: true,
    };
  },
  methods: {
    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
      // this simple editor doubles any value entered into the input
      return this.value;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
      // our editor will reject any value greater than 1000
      return this.value > 1000;
    },
  },
  mounted() {
    const colId = this.params.column.colId;
    const DistributionZone = this.params.data.id;
    if (colId === DistributionZone) {
      this.editable = false;
      this.value = null;
    } else {
      this.value = this.params.value;
    }
    this.$nextTick(() => this.$refs.input.focus());
  },
};
</script>

<style>
</style>
