var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { "is-active": _vm.showRouteHubModal },
      staticStyle: { "z-index": "1005 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("nav", { staticClass: "level is-flex is-flex-grow-1" }, [
            _vm.saving
              ? _c("strong", { staticClass: "has-text-warning level-left" }, [
                  _vm._v(_vm._s(_vm.saving ? "Saving..." : "")),
                ])
              : _vm._e(),
            _c("strong", { staticClass: "is-size-6 level-item" }, [
              _vm._v(
                "Time/Routing Table (Days it take to go from Hub A to Hub B.)"
              ),
            ]),
            _c(
              "button",
              { staticClass: "level-right is-success button is-small mx-2" },
              [_vm._v("Upload File")]
            ),
            _c("button", {
              staticClass: "delete level-right",
              attrs: { "aria-label": "close" },
              on: { click: _vm.toggleClose },
            }),
          ]),
        ]),
        _c(
          "section",
          { staticClass: "modal-card-body" },
          [
            _c("ag-grid-vue", {
              staticClass: "ag-theme-balham",
              staticStyle: { width: "92vw", height: "80vh" },
              attrs: {
                columnDefs: _vm.columnDefs,
                rowData: _vm.rowData,
                defaultColDef: _vm.defaultColDef,
                id: "myGrid",
                rowDrag: false,
                suppressSizeToFit: "true",
                suppressResize: "true",
              },
              on: {
                "grid-ready": _vm.onGridReady,
                "row-editing-started": _vm.onRowEditingStarted,
                "row-editing-stopped": _vm.onRowEditingStopped,
                "cell-editing-started": _vm.onCellEditingStarted,
                "cell-editing-stopped": _vm.onCellEditingStopped,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }